import React from "react";
import { LandingBanner } from "./common_elements";
import { WhiteBackgroundDivWithText } from "./common_elements";
import BannerImage from "../assets/images/about_us_banner_div.jpg"
import CoFounderImage from "../assets/images/co-founder.jpeg"
import "./style_files/agents.css"

export function Agents(){
    return(
        <div>
        <LandingBanner
        bannerImage={BannerImage}
        bannerText="Meet The Ibriyal Living Team"
        bannerTagline=""
        currentPageName="Agents"
        currentPageUrl="/agents"
        />
        <WhiteBackgroundDivWithText
            Heading1=""
            Heading2="Discover the Expertise and Dedication of Our Exceptional Team"
            Paragraph1="At Ibriyal Living, our success is a direct result of our exceptional team of real estate professionals. Each member of our team is fully licensed and certified, bringing unparalleled expertise and value to our firm. Our agents are specialists in the real estate industry, with deep knowledge of the luxury property market in Dubai."
            Paragraph2="Our team is well-versed in the intricacies of high-end real estate and works closely with leading developers in the region. This collaboration allows us to provide our clients with premier real estate solutions and unparalleled service."
            />
        
        </div>
    );
}
function CoFounder(){
    return(
    <div class="co-founder-container">
    <div class="container">
        <div class="row">
           
            <div class="col-md-6 d-flex justify-content-center align-items-center">
                <img src={CoFounderImage} alt="Co-Founder Image" class="co-founder-image" />
            </div>

         
            <div class="col-md-6">
                <h1 style={{fontFamily:"Oswald",fontWeight:600}}>Mohammed Matheen</h1>
                <h3 style={{fontFamily:"Oswald",fontWeight:600}}>Co-founder & Managing Director</h3>
                <h5 style={{fontFamily:"Oswald",fontWeight:600}}>BRN:67320</h5>
                <div style={{marginTop:'2em'}}>
                <p>
                As a dedicated sales professional in Dubai’s real estate market, I have had the privilege of assisting both domestic and international investors in discovering valuable opportunities. My approach is guided by a deep understanding of market trends and strategic insights, allowing me to help clients build successful investment portfolios. To date, I’ve facilitated transactions involving over 100 properties, with a combined value exceeding 150 million AED. Each investment is carefully managed to ensure it aligns with clients' financial goals and delivers meaningful returns.
                </p>
                <p>
                With more than a decade of experience in the industry, I focus on providing tailored investment strategies across various sectors, including residential off-plan projects, secondary market properties, and rental investments. My goal is not just to close deals but to support my clients in achieving long-term success. By fostering strong relationships and offering thoughtful guidance, I strive to deliver value and contribute to their continued growth in Dubai’s dynamic real estate landscape.
                </p>
                </div>
                
            </div>
        </div>
    </div>
</div>
    );
}